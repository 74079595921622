import { IExportMilestone, IExportSubworkflow, IExportWorkflow } from "../store/export/types";
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts'
import { Margins, TDocumentDefinitions } from "pdfmake/interfaces";
import { IFeature } from "../store/features/types";


export function generatePdf(projectJson: IExportWorkflow[]) {

    const docDefinition = buildDefinition(projectJson);

    ; (pdfMake as any).vfs = pdfFonts.pdfMake.vfs
    pdfMake.createPdf(docDefinition).download()

}

function buildDefinition(projectJson: IExportWorkflow[]): TDocumentDefinitions {
    let listeWf = projectJson.map((w, index) => {
        let titre = { text: (index + 1) + '. ' + w.master.title, style: 'titrewf' };
        let mapSf = buildSf(w.children, index + 1);
        let tabwk = [titre, mapSf];
        return tabwk;
    });
    let docDefinition = {
        content: listeWf,
        styles: {
            titrewf: {
                fontSize: 16,
                bold: true,
                color: '#5B9BD5',
                margin: [0, 0, 0, 20] as Margins
            },
            titreswf: {
                fontSize: 15,
                bold: true,
                color: '#5B9BD5',
                margin: [10, 0, 0, 20] as Margins
            },
            titrems: {
                fontSize: 14,
                color: '#f26f21',
                margin: [20, 0, 0, 20] as Margins
            },
            titrefeature: {
                fontSize: 12,
                //decoration:'underline',
                margin: [0, 10, 0, 10] as Margins
            },
            contenuFeature: {
                fontSize: 10,
                margin: [0, 10, 0, 20] as Margins
            }
        }
    }
    return docDefinition;
}

function buildSf(children: IExportSubworkflow[], index: number) {
    let listeSwf = children.map((sw, sindex) => {
        let okSubI = sindex + 1;
        let numerotationTitle = index + '.' + okSubI;
        let titre = { text: numerotationTitle + '. ' + sw.master.title, style: 'titreswf' };
        let mapMs = buildMs(sw.children, numerotationTitle);
        let sauteLigne = { text: '\n' }
        let tabsubwk = [titre,  mapMs];
        return tabsubwk;
    });
    return listeSwf;
}

function buildMs(children: IExportMilestone[], numerotationTitle: string) {
    let listeMs = children.map((ms, index) => {
        let okSubI = index + 1;
        let msnumerotationTitle = numerotationTitle + '.' + okSubI;
        let titre = { text: msnumerotationTitle + '. ' + ms.master.title, style: 'titrems' };
        let mapf = buildFeatures(ms.children, msnumerotationTitle);
        let sauteLigne = { text: '\n' }
        let tabsubwk = [titre, mapf, sauteLigne];
        return tabsubwk;
    });
    return listeMs;
}

function buildFeatures(children: IFeature[], msnumerotationTitle: string) {
    let listeF = children.map((f, index) => {
        let titre = { text: [{ text: f.numerotation, bold: true }, { text: ' : ' }, { text: f.title },{text:' ('},{text:f.annotations},{text:')'}], style: 'titrefeature' };
        let contenu = { table: { body: [[{ text: f.description }]] } };
        let sauteLigne = { text: '\n' }
        let tabFeature = [{ stack: [titre, sauteLigne, contenu], style: 'contenuFeature' }];
        return tabFeature;
    });
    return listeF;
}

