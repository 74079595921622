import React, { Component } from 'react';
import { IAccount } from '../store/application/types';
import { Link } from 'react-router-dom'
import ContextMenu from './ContextMenu';


interface Props {
  account: IAccount
  workspaceName?: string
}

class Header extends Component<Props> {

  render() {
    return (
      <header className="bg-gray-200">
        <div className="flex items-center p-1 ">
          <div className="flex text-lg   m-1 w-100 ">
            <b><Link to="/">Featmap personalis&eacute; pour Nartex</Link></b>

          </div>
          {this.props.workspaceName ?
            <div className="text-lg "><Link to={"/" + this.props.workspaceName}>Projets</Link></div>
            :
            null
          }

          <div className="flex flex-grow  ">
            <div className="flex p-1  rounded items-center ">
              <ContextMenu icon="account_circle" >
                <div className="rounded bg-white shadow-md absolute mt-8 top-0 right-0 min-w-full text-sm" >
                  <ul className="list-reset">
                    {this.props.workspaceName ?
                      <li className="whitespace-nowrap px-4 py-2 block text-gray-600">
                        Workspace en cours d'utilisation :  <b><Link to={"/" + this.props.workspaceName}>{this.props.workspaceName}</Link> </b> <Link className="p-1 flex-no-shrink font-bold rounded text-xs  bg-gray-300" to="/account/workspaces">Change </Link>
                      </li>
                      : null
                    }
                    {this.props.workspaceName ?
                      <li className="whitespace-nowrap px-4 py-2 block text-black hover:bg-gray-200 ">
                        <Link to={"/" + this.props.workspaceName + "/settings"} >Param�tres du Workspace</Link>
                      </li>
                      : null
                    }
                    <li className="whitespace-nowrap px-4 py-2 block text-black ">
                      <Link to={"/account/workspaces"} >Les workspaces auxquels j'ai acc&egrave;</Link>
                    </li>
                    <li className="whitespace-nowrap block text-black ">
                      <hr className="border-b" />
                    </li>
                    <li className="whitespace-nowrap px-4 py-2 block text-gray-600">
                    Logu&eacute; en tant que <em>{this.props.account.email}</em>
                    </li>

                    <li ><Link className="whitespace-nowrap px-4 py-2 block text-black hover:bg-gray-200" to="/account/settings">Param&egrave;tres du compte</Link> </li>
                    <li ><Link className="whitespace-nowrap px-4 py-2 block text-black hover:bg-gray-200" to="/account/logout">Se d&eacute;connecter</Link> </li>
                  </ul>
                </div>

              </ContextMenu>

            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
