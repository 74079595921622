import { INxFeaturePersona } from "./types";
import { action } from 'typesafe-actions'

export enum ActionTypes {
    CREATE_NX_FEATURE_PERSONA = 'CREATE_NX_FEATURE_PERSONA',
    LOAD_NX_FEATURE_PERSONAS = 'LOAD_NX_FEATURE_PERSONAS',
    UPDATE_NX_FEATURE_PERSONA = 'UPDATE_NX_FEATURE_PERSONA',
    DELETE_NX_FEATURE_PERSONA = 'DELETE_NX_FEATURE_PERSONA',

}

export interface createNxFeaturePersona { type: ActionTypes.CREATE_NX_FEATURE_PERSONA, payload: INxFeaturePersona }
export const createNxFeaturePersonaAction = (x: INxFeaturePersona) => action(ActionTypes.CREATE_NX_FEATURE_PERSONA, x)

export interface loadNxFeaturePersonas { type: ActionTypes.LOAD_NX_FEATURE_PERSONAS, payload: INxFeaturePersona[] }
export const loadNxFeaturePersonasAction = (x: INxFeaturePersona[]) => action(ActionTypes.LOAD_NX_FEATURE_PERSONAS, x)

export interface updateNxFeaturePersona { type: ActionTypes.UPDATE_NX_FEATURE_PERSONA, payload: INxFeaturePersona }
export const updateNxFeaturePersonaAction = (x: INxFeaturePersona) => action(ActionTypes.UPDATE_NX_FEATURE_PERSONA, x)

export interface deleteNxFeaturePersona { type: ActionTypes.DELETE_NX_FEATURE_PERSONA, payload: string }
export const deleteNxFeaturePersonaAction = (x: string) => action(ActionTypes.DELETE_NX_FEATURE_PERSONA, x)


export type Actions = createNxFeaturePersona | loadNxFeaturePersonas | updateNxFeaturePersona | deleteNxFeaturePersona





